<script setup>
import { onMounted, ref } from 'vue';
const flakes = ref([])

class Flake {
  constructor() {
    this.top = Math.random() * 5;
    this.left = Math.random() * 100;
  }

  update() {
    this.top = this.top + 0.1;
  }

  reset() {
    this.top = -(Math.random() * 10);
    this.left = Math.random() * 100;
  }
}

const update = () => {
  flakes.value.forEach(flake => {
    flake.update(); 

    // 48px to vh
    const maxVh = 100 / window.innerHeight * 48;
    if (flake.top > maxVh) {
      flake.reset();
    }
  });

  //requestAnimationFrame(update);
}

setInterval(() => {
  update();
}, 1000 / 60);


onMounted(() => {
  let numFlakes = 10;
  if (window.innerWidth < 768) numFlakes = 5;
  if (window.innerWidth < 480) numFlakes = 3;
  for (let i = 0; i < numFlakes; i++) {
    flakes.value.push(new Flake());
  }

  //requestAnimationFrame(update);
})
</script>

<template>
  <div class="snow-overlay h-[48px]">
    <div v-for="flake in flakes" :key="flake" :style="{ top: flake.top + 'vh', left: flake.left + 'vw' }" class="w-fit">
      &#10052;
    </div>
  </div>
</template>

<style scoped>
.snow-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 2001;
  container-type: inline-size;
}

.snow-overlay div {
  position: absolute;
  color: white;
  font-size: 2em;
  opacity: 0.3;
}

.snow-overlay div:nth-child(2n) {
  filter: blur(1px);
  font-size: 40px;
}

.snow-overlay div:nth-child(6n) {
  filter: blur(2px);
  font-size: 30px;
}

.snow-overlay div:nth-child(10n) {
  filter: blur(3px);
  font-size: 20px;
}


</style>
export const loyaltyReasons = [
  "Processo não está concluído",
  "Cliente não está interesado/a",
  "Cliente já recebeu a proposta por parte do Call Center",
  "Não foi recebido pelo proprietário",
  "Situação de possivel conflito com cliente",
  "Outro",
];

export const voucherList = [
  { label: 'Telemóveis (10%)', value: 'SMX10' },
  { label: 'Televisores (15%)', value: 'STV15' },
  { label: 'Linha Branca (20%)', value: 'SDA20' },
];

export const campaignList = [
  { label: 'CRP', value: 'CRP' },
  { label: 'JetBot', value: 'JetBot' },
];

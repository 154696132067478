import script from "./LoginView.vue?vue&type=script&setup=true&lang=js"
export * from "./LoginView.vue?vue&type=script&setup=true&lang=js"

import "./LoginView.vue?vue&type=style&index=0&id=b51acdee&lang=css"

const __exports__ = script;

export default __exports__
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCheckbox,QForm,QInput,QBtn});

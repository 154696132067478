import { io } from "socket.io-client";

class SocketService {
  // Private properties
  #socket;
  #eventHandlers;

  constructor(url) {
    if (SocketService.instance) {
      return SocketService.instance;
    }

    this.#socket = io(url, {
      path: '/socket.io',
      transports: ['websocket'],
      query: { token: localStorage.getItem('token') }
    });
    
    this.#eventHandlers = {};

    this.#socket.on("connect", () => {
      //console.log("Socket connected");
    });

    this.#socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    this.#socket.on("error", (error) => {
      console.error("Socket error:", error);
    });

    SocketService.instance = this;
  }

  subscribe(event, callback) {
    if (!this.#eventHandlers[event]) {
      this.#eventHandlers[event] = [];
    }

    this.#eventHandlers[event].push(callback);
    this.#socket.on(event, callback);
  }

  unsubscribe(event, callback) {
    if (!this.#eventHandlers[event]) {
      return;
    }

    const index = this.#eventHandlers[event].indexOf(callback);
    if (index !== -1) {
      this.#eventHandlers[event].splice(index, 1);
    }

    this.#socket.off(event, callback);
  }

  emit(event, data) {
    this.#socket.emit(event, data);
  }

  disconnect() {
    this.#socket.disconnect();
  }

  get id() {
    return this.#socket.id;
  }
}

const instance = new SocketService(process.env.VUE_APP_BACKEND_URL);

export default instance;

import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Notify from 'quasar/src/plugins/notify/Notify.js';;

import './styles/quasar.sass'
import iconSet from 'quasar/icon-set/fontawesome-v6.js'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      positive: '#1da43d',
      negative: '#b71c1c',
    },
    dark: 'auto',
  },
  plugins: {
    Dialog,
    Notify
  },
  iconSet: iconSet
}
import { createApp } from 'vue'
import App from '@/App.vue'
import Quasar from 'quasar/src/vue-plugin.js';;
import moment from 'moment';
import quasarUserOptions from '@/quasar-user-options'
import router from '@/router'
import pinia from '@/stores'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { useUserStore } from './stores/user';
import Socket from '@/services/Socket.js'
import { Sortable } from 'vue-sortable'
import './assets/tailwind.css'
import { loadScript } from 'vue-plugin-load-script'
import debounce  from 'v-debounce'
import money from 'v-money3'
import VuePlugin from '@quasar/quasar-ui-qmarkdown'
import '@quasar/quasar-ui-qmarkdown/dist/index.css'

library.add(fas)

let app = createApp(App)

moment.locale('pt')

app.config.globalProperties.moment = moment;

app.use(pinia)

const userStore = useUserStore()
quasarUserOptions.config.dark = userStore.darkMode

app.use(Quasar, quasarUserOptions)
app.use(Sortable)
app.use(debounce);
app.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: ' €',
  precision: 2,
  masked: false
})
app.use(VuePlugin)

app.use(router)


app.component('FaIcon', FontAwesomeIcon)

app.config.globalProperties.$filters = {
  PhoneNumber(value) {
    if (!value) return ''
    if (value.length != 9) return value
    let formattedNumber = value.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')
    return formattedNumber
  }
}

app.mount('#app')

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // Check if user is logged in
    if (!useUserStore().user) return

    navigator.serviceWorker.register('/service-worker.js')
      .then(async registration => {
        if ('PushManager' in window) {
          if (Notification.permission !== 'granted') {
            requestNotificationPermission()
          }
          let subscription = await registration.pushManager.getSubscription()

          if (!subscription) {
            subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: 'BOaYrhHLhS3Zo8LzzD9uoC6XzuttB1F2jY60DdTWEzfrRD5s0F-0H461ShymJXwKQUepdGSnSatQHZ7AyfHRmn4'
            })
          } 
          
          axios.post('/api/push/register', subscription);
        }
      })
      .catch(registrationError => {
        console.error('Service worker registration failed: ', registrationError)
      })
  })
}

function requestNotificationPermission() {
  // Check if browser supports notifications
  if (!('Notification' in window)) return;

  // Request permission
  Notification.requestPermission()
    .then(permission => {
      console.log(`Notification permission: ${permission}`);
    })
    .catch(error => {
      console.error('Notification permission error: ', error)
    })
}

Socket.subscribe("newTicket", () => {
  useUserStore().fetchNotificationsCount();
});

Socket.subscribe("ticketReply", () => {
  useUserStore().fetchNotificationsCount();
})

useUserStore().refresh();


(async () => {
  try {
    window['BarcodeDetector'].getSupportedFormats();
  } catch {
    await loadScript('https://cdn.jsdelivr.net/npm/@undecaf/zbar-wasm/dist/index.js');
    await loadScript('https://cdn.jsdelivr.net/npm/@undecaf/barcode-detector-polyfill/dist/index.js');
    // eslint-disable-next-line no-undef
    window['BarcodeDetector'] = barcodeDetectorPolyfill?.BarcodeDetectorPolyfill;
  }
})();

<script setup>
import logo from '@/assets/infoservice.png'
import logoWhite from '@/assets/infoservice-w.png'
import { ref, computed } from 'vue'
import { useQuasar } from 'quasar'
import { useUserStore } from '@/stores/user'
import Api from '@/services/Api'

const $q = useQuasar()
const store = useUserStore()

const username = ref(null)
const password = ref(null)
const remember = ref(false)
const loading = ref(false)

const dark = ref($q.dark.isActive)

const updateDark = () => {
  $q.dark.set(dark.value)
}

const logoImg = computed(() => {
  return $q.dark.isActive ? logoWhite : logo
})

const login = async () => {
  try {
    if (!username.value?.length || !password.value?.length)
    return

    loading.value = true
    
    let { data } = await Api.post('/login', {
      username: username.value,
      password: password.value,
      remember: remember.value,
    })
    
    if (data.redirect) {
      store.setUser(data.user);
      localStorage.setItem('token', data.token);
      
      $q.notify({
        message: `Olá ${data.user.name}, vais ser redirecionado(a) dentro de instantes.`,
        color: 'positive'
      })
      
      setTimeout(() => window.location = data.redirect, 1000);
    }
  }
  catch (error) {
    $q.notify({
      message: error.response?.data?.message || 'Erro desconhecido',
      color: 'negative',
    })
    console.log(error)
    loading.value = false
  }

}
</script>

<template>
  <q-checkbox v-model="dark" label="Dark mode" @click="updateDark" />
  <div class="flex flex-col items-center justify-center md:h-screen">
    <div class="max-w-screen-md mx-auto center rounded-xl p-8" :class="{
      'bg-stone-900': $q.dark.isActive,
      'bg-stone-100': !$q.dark.isActive
    }">
      <div class="logo mb-12">
        <img :src="logoImg" alt="Infoserivce">
      </div>
      <q-form class="loginForm" @submit="login">
        <div class="q-mb-sm">
          <q-input outlined v-model="username" label="Utlizador" :rules="[val => val?.length > 0 || 'Campo necessário']"/>
        </div>
        <div class="q-mb-sm">
          <q-input outlined v-model="password" label="Password" type="password" :rules="[val => val?.length > 0 || 'Campo necessário']"/>
        </div>
        <div class="q-mb-sm">
          <q-checkbox v-model="remember" label="Lembrar-me"/>
        </div>

        <q-btn type="submit" class="btn btn-primary w-full" color="primary" label="Entrar" :disable="loading"/>
      </q-form>
    </div>
  </div>
</template>

<style>
.loginPage {
  min-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
}

.logo {
  margin-bottom: 25px;
  text-align: center;
}

@media (max-width: 500px) {
  .loginPage { min-width: 90%; }
}
</style>
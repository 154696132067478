<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <div class="q-pa-md">
        <div class="text-center">
          <b>Submeter loyalty</b>
        </div>
        <q-form 
          @submit="save"
        > 
          <q-select v-model="technician" :options="technicians" label="Técnico" v-if="user.admin || !technician" />
          <q-input v-model="firstName" label="Primeiro nome" :rules="[requiredField]" />
          <q-input v-model="lastName" label="Ultimo nome" :rules="[requiredField]" />
          <q-input v-model="email" label="Endereço de email" :rules="[requiredField, v => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Email inválio']" />
          <q-input v-model="phone" label="Número de Telemóvel" type="number" />
          <q-select v-model="campaign" :options="campaignList" label="Campanha" :rules="[v => !!v.value || 'Campo obrigatório']" />
          <q-select v-model="voucher" :options="voucherList" label="Voucher" :rules="[v => !!v.value || 'Campo obrigatório']" />

          <q-btn-group class="q-mt-sm">
            <q-btn label="Cancelar" color="red-8" @click="onDialogCancel" />
            <q-btn label="Enviar" color="green" type="submit" />
          </q-btn-group>
        </q-form>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, defineEmits, defineExpose, onMounted } from 'vue'
import { useDialogPluginComponent, useQuasar } from 'quasar'
import { voucherList, campaignList } from '@/utils/loyalties.js'
import { useUserStore } from '@/stores/user.js'

import axios from 'axios'

const { user } = useUserStore()
const requiredField = v => v.length || 'Campo obrigatório'

const $q = useQuasar()
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const technicians = ref([]);
const technician = ref(null);
const firstName = ref('');
const lastName = ref('');
const email = ref('');
const phone = ref('');
const campaign = ref(campaignList.find(v => v.value == 'JetBot') || null);
const voucher = ref('');

defineEmits([...useDialogPluginComponent.emits])


const save = async () => {
  try {
    const data = {
      technician: technician.value.value,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      campaign: campaign.value?.value,
      voucher: voucher.value?.value,
    }

    let { data: loyalty } = await axios.post(`/api/loyalty/custom`, data);

    $q.notify({
      message: 'Loyalty criado com sucesso, a submeter para a Samsung...',
      color: 'primary'
    })

    onDialogOK();

    try {
      await axios.post(`/api/loyalty/submit`, { id: loyalty._id });
    } catch (error) {
      $q.notify({
        message: 'Erro ao submeter para a Samsung...',
        color: 'negative'
      })
    }

  } catch (error) {
    console.log(error)
    $q.notify({
      message: 'Erro ao enviar dados...',
      color: 'negative'
    })
  }
}

onMounted(async () => {
  let { data } = await axios.get(`/api/user/list`);
  technicians.value = data
    .filter(v => v.active)
    .filter(v => !!v.samsungId)
    .map(v => ({
      label: v.name,
      value: v._id,
    }));

    if (technicians.value.find(v => v.value == user._id)) {
      technician.value = technicians.value.find(v => v.value == user._id);
    } else if (user.external) {
      const renato = data.find(v => v.username == 'renato');
      technician.value = technicians.value.find(v => v.value == renato._id);
    }
})

defineExpose({
  dialogRef,
  onDialogOK,
  onDialogCancel,
  onDialogHide,
})
</script>